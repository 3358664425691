import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import pages from './pages';

// import Account from './components/account';

import './App.css';
import 'bootstrap-css-only';

const NotFound = () => <h1>404 NotFound</h1>;

function App () {
  return (
    // <Account>
      <Router>
        <div className="app">
          <Routes>
            <Route exact path="/" component={pages.customers} />
              {Object.keys(pages).map((key) => {
                const Page = pages[key];
                return <Route key={key} path={`/${key.replace(/_/g, '/')}`} element={<Page />} />;
              })}
            <Route component={NotFound} />
          </Routes>
        </div>
      </Router>
    // </Account>
  );
}

export default App;
