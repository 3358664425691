import { useState } from 'react';
import Header from '../components/header';

const CalcButton = (props) => {
  const [pressed, press] = useState(false);
  const style = { paddingRight: '5px' };
  if (pressed) {
    style.transform = 'translate(1px,1px)';
  }
  const onClick = () => {
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };
  return (
    <div className="col-3 pl-0" style={style}>
      <div className="color-bg-clear py-3" onClick={onClick} onMouseDown={() => press(true)} onMouseUp={() => press(false)} onMouseOut={() => press(false)}>
        {props.label}
      </div>
    </div>   
  );
};

const Calc = (props) => {
  const [value, setValue] = useState('');
  return (
    <div className="card mt-2">
      <div className="card-body color-bg-secondary p-2">
        <strong className="color-text-clear strong">現金</strong>
        <div className="color-bg-clear color-text-secondary text-right px-2 py-1">{value || '(未入力)'}</div>
        <div className="container text-center my-1" style={{paddingRight: '10px', paddingLeft: '15px'}}>
          <div className="row mb-1">
            <CalcButton label="7" onClick={() => { setValue(String(value) + '7') }}></CalcButton>
            <CalcButton label="8" onClick={() => { setValue(String(value) + '8') }}></CalcButton>
            <CalcButton label="9" onClick={() => { setValue(String(value) + '9') }}></CalcButton>
            <CalcButton label="AC" onClick={() => { setValue('') }}></CalcButton>
          </div>
          <div className="row mb-1">
            <CalcButton label="4" onClick={() => { setValue(String(value) + '4') }}></CalcButton>
            <CalcButton label="5" onClick={() => { setValue(String(value) + '5') }}></CalcButton>
            <CalcButton label="6" onClick={() => { setValue(String(value) + '6') }}></CalcButton>
            <CalcButton label="C" onClick={() => { setValue('') }}></CalcButton>
          </div>
          <div className="row mb-1">
            <CalcButton label="1" onClick={() => { setValue(String(value) + '1') }}></CalcButton>
            <CalcButton label="2" onClick={() => { setValue(String(value) + '2') }}></CalcButton>
            <CalcButton label="3" onClick={() => { setValue(String(value) + '3') }}></CalcButton>
            <CalcButton label="+" onClick={() => { setValue('') }}></CalcButton>
          </div>
          <div className="row mb-1">
            <CalcButton label="0" onClick={() => { value && setValue(String(value) + '0') }}></CalcButton>
            <CalcButton label="00" onClick={() => { value &&  setValue(String(value) + '00') }}></CalcButton>
            <CalcButton label="万"></CalcButton>
            <CalcButton label="計"></CalcButton>
          </div>
        </div>
      </div>
    </div>
  );
};

// お支払い画面
export default function () {
  return (
    <div className="app">
      <Header>お支払い</Header>
      <div className="container-fluid py-2">
        <div className="btn-toolbar">
          <button className="btn btn-danger color-bg-clear text-danger" style={{borderRadius: '2em'}}>会計中断</button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="card">
              <div className="card-body color-bg-secondary-light pl-4 pr-2">
                <div className="row" style={{fontSize: '2.5rem', fontWeight: 'bold'}}>
                  <div className="col-6">合計</div>
                  <div className="col-6 text-right">&yen;1,020</div>
                </div>
                <div className="row px-3" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                  <div className="col-6">内消費税</div>
                  <div className="col-6 text-right">&yen;93</div>
                </div>
                <div className="row px-3" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                  <div className="col-6">10%合計</div>
                  <div className="col-6 text-right">&yen;1,020</div>
                </div>
                <div className="row px-3" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                  <div className="col-6">10%内消費税</div>
                  <div className="col-6 text-right">&yen;93</div>
                </div>
                <div className="row px-3" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                  <div className="col-6">8%合計</div>
                  <div className="col-6 text-right">&yen;0</div>
                </div>
                <div className="row px-3" style={{fontSize: '1rem', fontWeight: 'normal'}}>
                  <div className="col-6">8%内消費税</div>
                  <div className="col-6 text-right">&yen;0</div>
                </div>
              </div>
            </div>
            <div className="text-right py-1">
              <button className="btn btn-secondary color-bg-clear text-dark" style={{borderRadius: '2em'}}>明細表示</button>
            </div>
            <div className="list-group">
              <div className="list-group-item color-bg-positive color-text-clear fw-bold">
                現金
                <div className="float-right text-right">&yen;1,100</div>
              </div>
              <div className="list-group-item">
                クレジット
                <div className="float-right text-right">&yen;0</div>
              </div>
              <div className="list-group-item">
                電子マネー
                <div className="float-right text-right">&yen;0</div>
              </div>
              <div className="list-group-item">
                商品券
                <div className="float-right text-right">&yen;0</div>
              </div>
            </div>
            <div className="text-right py-1">
              <button className="btn btn-link text-dark" style={{borderRadius: '2em'}}>+その他</button>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="card">
              <div className="card-body color-bg-clear pl-4">
                <div className="row" style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                  <div className="col-6">合計</div>
                  <div className="col-6 text-right">&yen;1,020</div>
                </div>
                <div className="row" style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                  <div className="col-6">お預り</div>
                  <div className="col-6 text-right">&yen;1,100</div>
                </div>
              </div>
              <div className="card-footer color-bg-positive-light pl-4">
                <div className="row" style={{ fontSize: '1.3rem', fontWeight: 'bold'}}>
                  <div className="col-6">お釣り</div>
                  <div className="col-6 text-right">&yen;80</div>
                </div>
              </div>
            </div>
            <Calc></Calc>
          </div>
        </div>
      </div>
    </div>
  );
}