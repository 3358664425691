import { useState } from 'react';
import Header from '../components/header';

const data = [
  {
    location: '01',
    number: 2,
    from: '11:00',
    status: '会計前',
    amount: '2,000',
    items: [
      { name: '生ビール（中）', amount: 960, number: 2 },
      { name: '特製つくね', amount: 280, number: 1 }
    ]
  },
  {
    location: '03',
    number: 3,
    from: '11:30',
    status: '会計前',
    amount: '2,000',
    items: [
      { name: '生ビール（中）', amount: 480, number: 1 },
      { name: 'ゆずサワー', amount: 780, number: 2 },
      { name: '鉄板牛ステーキ', amount: 800, number: 1 },
      { name: '肉じゃがコロッケ', amount: 280, number: 1 }
    ]
  },
  {
    location: '06',
    number: 1,
    from: '11:35',
    status: '会計中',
    amount: '2,000',
    items: [
      { name: '生ビール（中）', amount: 480, number: 1 },
      { name: '刺身盛り合わせ', amount: 1600, number: 2 }
    ]
  }
];

export default function () {
  const [selectedCustomer, selectCustomer] = useState(null);
  return (
    <div className="app">
      <Header>お客様一覧</Header>
      <div className="container-fluid">
        <div className="row border-bottom">
          <div className="col-md-6 col-12 px-0 border-right pb-2">
            <div className="color-bg-primary color-text-clear clearfix my-3 px-2 py-2">
              <div className="float-left" style={{fontWeight: 'bold', lineHeight: 2}}>来店一覧</div>
              <div className="float-right btn-toolbar p-1">
                <button className="btn btn-sm color-bg-clear color-text-primary color-border-clear" style={{borderRadius: '2em'}}>新規来店</button>
                <button className="btn btn-sm color-bg-clear color-text-alert color-border-clear ml-2" style={{borderRadius: '2em'}}>来店取消</button>
              </div>
            </div>
            <div className="px-1">
              <div className="card border-0">
                <div className="card-body p-1">
                  <div className="row small">
                    <div className="col-4">テーブル</div>
                    <div className="col-2">人数</div>
                    <div className="col-2">来店時刻</div>
                    <div className="col-2">ステータス</div>
                    <div className="col-2">金額</div>
                  </div>
                </div>
              </div>
              {data.map((d, i) => (
                <div key={`customer-${i}`} className="card mb-1" onClick={() => selectCustomer(d)}>
                  <div className="card-body p-1">
                    <div className="row">
                      <div className="col-4">{d.location}</div>
                      <div className="col-2">{d.number}名</div>
                      <div className="col-2">{d.from}〜</div>
                      <div className="col-2">{d.status}</div>
                      <div className="col-2">&yen;{d.amount}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6 col-12 px-0 pb-2">
            {selectedCustomer && (
              <div>
                <div className="color-bg-positive-light color-text-clear clearfix my-3 px-2 py-2">
                  <div className="float-left color-text-primary" style={{fontWeight: 'bold', fontSize: '1.5rem', lineHeight: 1.5}}>{selectedCustomer.location}</div>
                  <div className="float-right btn-toolbar p-1">
                    <button className="btn btn-sm color-text-main color-bg-positive-light color-border-positive-pastel ml-2" style={{borderRadius: '2em'}}>詳細</button>
                  </div>
                </div>
                <div className="container-fluid text-right mb-2">
                  <a href='/order' className="btn color-bg-primary color-text-clear mr-2" style={{borderRadius: '2em'}}>商品追加</a>
                  <button className="btn color-bg-alert color-text-clear" style={{borderRadius: '2em'}}>注文キャンセル</button>
                </div>
                <div className="container-fluid">
                  <div className="card color-bg-positive">
                    <div className="card-body py-0">
                      <div className="color-text-clear clearfix px-2 py-2">
                        <div className="float-left" style={{ fontWeight: 'bold', lineHeight: 2 }}>未会計商品</div>
                        <div className="float-right btn-toolbar p-1">
                          <button className="btn btn-sm color-bg-clear color-text-main color-border-clear ml-2" style={{borderRadius: '2em'}}>配膳チェック</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-0">
                      {selectedCustomer.items.map((d, i) => (
                        <div key={`item-${i}`} className="card mb-1">
                          <div className="card-body p-1">
                            <div className="row">
                              <div className="col-6">{d.name}</div>
                              <div className="col-3">&yen;{d.amount}</div>
                              <div className="col-3">{d.number}点</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedCustomer && (
          <div className="row border-bottom py-2">
            <div className="col-5 text-right">
              未会計商品合計
            </div>
            <div className="col-2 text-right">{selectedCustomer.items.length}点</div>
            <div className="col-2 text-right">&yen;{selectedCustomer.amount}</div>
            <div className="col-3 text-right">
              <button className="btn btn-sm color-bg-primary color-text-clear color-border-primary ml-2" style={{borderRadius: '2em'}}>会計へ進む &gt;</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}