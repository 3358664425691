import customers from './customers.js';
import account from './account';
// import graph from './graph';
import order from './order';

export default {
  customers,
  account,
  // graph,
  order
};
