import { useEffect } from 'react';
import dayjs from 'dayjs';
import 'bootstrap-css-only';
import { NumericFormat } from "react-number-format"

function calcHeight (d) {
  return (d / 130) * 450;
}
function forgeData () {
  const main = [];
  const sub = [];
  while (main.length < 12) {
    main.push(Math.round((70 + (Math.random() * 30)) * 10000));
    sub.push(Math.round((70 + (Math.random() * 30)) * 10000));
  }
  return { main, sub };
}

function Page () {
  const data = forgeData();
  return (
    <div className="App p-3">
      <header className="header"></header>
      <div className="clearfix">
        <div className="float-right small">表示日時: <span>{dayjs().format('YYYY/MM/DD HH:mm')}</span></div>
      </div>
      <div>
        <select onChange={() => { forgeData(); }}>
          <option>岐阜店</option>
          <option>大垣店</option>
        </select>
      </div>
      <div className="mt-3">
        <h2>月別売上グラフ</h2>
        <div className="border rounded p-2">
          <svg viewBox="0,0,928,500" width="100%" height="500" style={{maxWidth: '100%', maxHeight: '500px', height: 'auto'}}>
            <g className="bars" fill="steelblue">
              <g transform="translate(60,0)">
                {data.main.map((d, i) => {
                  const h = calcHeight(d / 10000);
                  return (
                    <g key={`main-${i}`} transform={`translate(${i * 70}, 0)`}>
                      <rect y={470 - h} height={h} width="30"></rect>
                    </g>
                  );
                })}
              </g>
            </g>
            <g className="subbars" fill="#B24555">
              <g transform="translate(90,0)">
                {data.sub.map((d, i) => {
                  const h = calcHeight(d / 10000);
                  return (
                    <g key={`sub-${i}`} transform={`translate(${i * 70}, 0)`}>
                      <rect y={470 - h} height={h} width="15"></rect>
                    </g>
                  );
                })}
              </g>
            </g>
            <g className="x-axis" transform="translate(0,470)" fill="none" fontSize="10" fontFamily="sans-serif" textAnchor="middle">
              <path className="domain" stroke="currentColor" d="M40,0H900"></path>
              <g transform="translate(90,0)">
                {data.main.map((d, i) => {
                  const h = calcHeight(d);
                  return (
                    <g key={`xaxis-${i}`} transform={`translate(${i * 70}, 0)`}>
                      <g className="tick" opacity="1">
                        <line stroke="currentColor" y2="6"></line>
                        <text fill="currentColor" y="9" dy="0.71em">{ i + 1 }</text>
                      </g>
                    </g>
                  );
                })}
              </g>
            </g>
            <g className="y-axis" transform="translate(40,0)" fill="none" fontSize="10" fontFamily="sans-serif" textAnchor="end">
              <g className="tick" opacity="1" transform="translate(0,470)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">0</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,435.3846153846154)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">10</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,400.7692307692308)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">20</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,366.1538461538462)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">30</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,331.5384615384615)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">40</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,296.9230769230769)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">50</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,262.3076923076924)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">60</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,227.69230769230765)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">70</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,193.07692307692307)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">80</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,158.46153846153845)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">90</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,123.84615384615383)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">100</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,89.23076923076923)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">110</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,54.61538461538464)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">120</text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,20)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">130</text>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="mt-3">
        <h2>月別売上集計表</h2>
        <div className="border rounded p-2">
          <table className="table table-bordered">
            <thead className="text-center">
              <tr>
                <th></th>
                <th>今年度</th>
                <th>前年度</th>
                <th>前年対比</th>
              </tr>
            </thead>
            <tbody className="text-right">
              {data.main.map((d, i) => {
                const m = d;
                const s = data.sub[i];
                return (
                  <tr key={`row-${i}`}>
                    <th className="text-center">{i + 1}月</th>
                    <td>
                      <NumericFormat value={m} displayType="text" thousandSeparator={true} suffix="円"/>
                    </td>
                    <td>
                      <NumericFormat value={s} displayType="text" thousandSeparator={true} suffix="円"/>
                    </td>
                    <td>
                      <NumericFormat value={ Math.round((m / s) * 1000) / 10 } displayType="text" thousandSeparator={true} suffix="%"/>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="text-right">
              <tr>
                <th className="text-center">合計</th>
                <td>
                  <NumericFormat value={data.main.reduce((sum, d) => { return sum + d }, 0)} displayType="text" thousandSeparator={true} suffix="円"/>
                </td>
                <td>
                  <NumericFormat value={data.sub.reduce((sum, d) => { return sum + d }, 0)} displayType="text" thousandSeparator={true} suffix="円"/>
                </td>
                <td>
                  <NumericFormat value={Math.round((data.main.reduce((sum, d) => sum + d, 0) / data.sub.reduce((sum, d) => sum + d, 0) * 1000)) / 10} displayType="text" thousandSeparator={true} suffix="%"/>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Page;
